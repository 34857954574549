import React from "react"
import styled from "styled-components"

import Layout from "../layouts"

import { withUnpublishedPreview } from "gatsby-source-prismic"

import {
  TextBlock,
  StyledButtonLink,
  StandardContainer,
} from "../components/common"

import SEO from "../components/seo"
import { Footer } from "../components/nav"

// Import Page Templates
import PageTemplate from "../templates/standardPage"
import UseCaseTemplate from "../templates/useCase"

const StyledErrorPage = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  background: ${props => props.theme.colors.darkBg};
  color: #fff;
  ${StandardContainer} {
    width: 100%;
  }
  ${StyledButtonLink} {
    margin-top: 1rem;
  }
  h1 {
    margin-bottom: 1rem;
  }
`

const NotFoundPage = () => (
  <Layout>
    <StyledErrorPage>
      <SEO title="404: Not found" />
      <StandardContainer>
        <TextBlock>
          <h1>404 Page not found</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </TextBlock>
        <StyledButtonLink to="/">View Homepage</StyledButtonLink>
      </StandardContainer>
    </StyledErrorPage>
    <Footer />
  </Layout>
)

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    standard_page: PageTemplate,
    use_case: UseCaseTemplate,
  },
})
